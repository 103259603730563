:root{
  /* --primary-color-dark: rgb(12, 12, 12); previous black */
  --current-font: 'IBM Plex Mono', monospace;
	--primary-color: rgb(50, 50, 50); 
  --primary-color-dark: #080808; /* OpenAI black */
  --primary-color-dark1: rgb(12, 12, 12);
  --primary-color-dark2: rgb(16, 16, 16);
  --primary-color-dark3: rgb(20, 20, 20);
  --primary-color-dark4: rgb(25, 25, 25);
  --primary-color-dark5: rgb(30, 30, 30);
  --primary-color-dark6: rgb(35, 35, 35);
  --primary-color-light: rgb(100, 100, 100);
  --primary-color-light2: rgb(150, 150, 150);
	--black: black;
	--white: white;
  --break-color: #eee;
  --accent-color-red: rgb(200, 0, 0);
  --accent-color-green: rgb(0, 200, 0);
  --accent-color-blue: rgb(0, 0, 200);
  --accent-color-yellow: rgb(255, 200, 0);
  --accent-color-gt: rgb(179, 163, 105);
  --accent-color-gh: rgb(45, 186, 78);
  background-color: #080808;
}

/* font families for website */
@import url('https://fonts.googleapis.com/css2?family=Geist&display=swap');
:root {
  --font-family-text: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-n27: 'N27', sans-serif;
  --font-family-gt: 'Roboto Slab', 'Roboto', sans-serif;
  --font-family-ibm-plex-mono: 'IBM Plex Mono', monospace;
  --font-family-geist: 'Geist Mono', sans-serif;
  --font-family-playwrite: "Playwrite CA", cursive;
  --font-family-georgia: "Georgia", serif;
  --font-family-inter: "Inter";
}

/* Manual Font Imports */
@font-face {
  font-family: 'Geist Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/geist-mono@latest/latin-400-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/geist-mono@latest/latin-400-normal.woff) format('woff');
}

@font-face {
  font-family: 'N27';
  src: url('../public/fonts/n27/Webfont/n27-regular-webfont.woff2') format('woff2'),
       url('../public/fonts/n27/Webfont/n27-regular-webfont.woff') format('woff'),
       url('../public/fonts/n27/Webfont/n27-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

::selection {
  background-color: var(--white);
  color: var(--black);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}