/** Under construction*/ 
.about-page-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	min-height: 100vh;
	background-color: var(--primary-color-dark);
	font-size: 1.5rem;
	color: var(--primary-color-light2);
}

.photography-link {
	color: var(--primary-color-light);
	text-decoration: none;
} .photography-link:hover {
	text-decoration: underline;
} .photography-link:visited {
	color: var(--primary-color-light);
}