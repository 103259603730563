.devicon-react-original,
.devicon-javascript-plain,
.devicon-java-plain,
.devicon-python-plain,
.devicon-c-plain,
.devicon-swift-plain,
.devicon-html5-plain,
.devicon-css3-plain,
.devicon-nodejs-plain,
.devicon-express-original,
.devicon-mongodb-plain-wordmark,
.devicon-bash-plain,
.devicon-git-plain,
.devicon-github-original {
	color: white;
    font-size: 80px; /* Adjust the size as needed */
	padding: 30px;
	border-radius: 20px;
}

