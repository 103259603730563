
/** navigation icon */
.hamburger {
  z-index: 1002;
} .hamburger:hover .line {
    stroke: var(--white);
}

.navigation-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100vh;
    background-color: var(--primary-color-dark);
    z-index: 1001;
} .navigation-subcontainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 70%;
} 
/** navigation list with respective links */
.navigation-list-2 {
	display: flex;
	flex-direction: column;
	list-style-type: none;
	justify-content: center;
	align-items: flex-start;
	background-color: var(--primary-color-dark);
	margin: 0;
	padding: 0;
	width: 50%;
} .nav-list-item {
	color: var(--white);
	text-decoration: none;
	margin: 10px 0;
	font-size: 4rem;
	font-family: var(--font-family-ibm-plex-mono);
  } .nav-list-item:hover {
	text-decoration: underline;
  } .nav-list-item:visited {
	color: var(--white);
  } .nav-list-info {
	color: var(--primary-color-light);
	font-family: var(--font-family-text);
}

/** navigation icon */
.ham {
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
	transition: transform 400ms;
	user-select: none;
}
  
/* Increase specificity for line color */
.ham .line {
	fill: none;
	stroke: var(--primary-color-light2) !important; /* Force color */
	stroke-width: 5;
	stroke-linecap: round;
	transition: 
	  stroke 0.3s ease,
	  stroke-dasharray 400ms,
	  stroke-dashoffset 400ms,
	  transform 400ms;
  }
  
  .hamburger:hover .line {
	stroke: var(--white);
  } .ham:hover .line {
	stroke: var(--white) !important;
  }

  .ham4 .top {
	stroke-dasharray: 40 121;
  }
  .ham4 .bottom {
	stroke-dasharray: 40 121;
  }
  .ham4.active .top {
	stroke-dashoffset: -68px;
  }
  .ham4.active .bottom {
	stroke-dashoffset: -68px;
  }

/** twitter is going to be a little better onwo that this 

//** finding the nature twitter is going to be a little better now thanks to this other media query 
that is going tto be a liot better than theprevious media query but finding the nature of the unvierse is going
to be a little better now
/** Media Queries for Mobile Devices */
@media (max-width: 500px) {
	.navigation-container {
		height: 100%;
		width: 90%;
	}
	.nav-list-item {
		font-size: 2.8rem;
	}
}