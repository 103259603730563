/**
 * Home page styles
*/
.home_page_ui {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: calc(13px + 2vmin);
    color: white;
    height: 100%;
    width: 100vw;
    min-height: 100vh;
} .home_page_intro {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100%;
    align-items: flex-start;
    align-self: center;
    margin-top: 175px;
    margin-bottom: 50px;
} @media (max-width: 1079px) {
    .home_page_intro {
        margin-top: 225px;
        margin-bottom: 255px;
    }
}

/** Name **/
.home_text_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: left;
    font-family: var(--current-font);

} .header-introduction_text {
    position: relative;
    color: var(--primary-color-light2);
    font-size: 0.8em;
    z-index: 1;
    margin: 0;
} .header-introduction_name {
    position: relative;
    width: 80%;
    font-size: 3.2em;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 20px;
    margin-right: 0;
    margin-left: 0;
    z-index: 1;
}

/** React-Typed **/
.header-introduction-typer-container {
    height: 125px;
    width: 100%;
} .header-introduction-typer {
    width: 100%;
    position: relative;
    font-size: 1.5em;
    color: var(--white);
    background: linear-gradient(270deg, var(--primary-color-dark2), var(--primary-color), 
                                        var(--primary-color-light), var(--primary-color-light2), 
                                        var(--primary-color-light));
    animation: gradientAnimation 4s ease infinite; /* Apply the animation */
    background-size: 200% 200%; /* Enlarge background size to ensure smooth transition */
    opacity: 1;
}

.home_text {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.header-introduction {
    animation: slide-in-blurred-bottom 1s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
} .header-information {
    margin: 0;
    animation: slide-in-blurred-bottom 2s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
} .navigation-list {
    animation: slide-in-blurred-bottom 3s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}


.select-button-container {
    border: none;
}

.select-button {
    position: absolute;
    left: 15%;
    bottom: 2rem;
    width: auto;
    height: 40px;
    color: var(--primary-color-light2);
    font-size: 0.8em;
    display: inline-flex;
    align-items: center;
    border: none;
    background: none;
}

.select-button:hover {
    color: var(--white);
    cursor: pointer;
} .select-button:focus {
    outline: none;
}

.georgia-tech {
	font-family: "Roboto Slab", serif;
	font-weight: bold;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
        transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
        filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    -webkit-filter: blur(0);
        filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
        transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
        filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    -webkit-filter: blur(0);
        filter: blur(0);
    opacity: 1;
  }
}

@keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
}

/**On screens with width 750px or less, decrease font size*/
@media (max-width: 750px) {
    .home_page_intro {
        margin-top: 225px;
        margin-bottom: 225px;
    } .header-introduction_name {
        font-size: 2.5em;
    } .header-introduction-typer {
        font-size: 1.5em;
    } .skill_container i {
        font-size: 4em;
    }

    /* Work experience */
    .work_container {
        width: 100%;
        padding: 0 10px; /* Add some padding for better spacing */
    }
    .work_title {
        font-size: 1em; /* Increase font size for better readability */
        padding-bottom: 20px; /* Adjust padding */
    }
    .work_tabs_container {
        flex-direction: column; /* Stack tabs and content vertically */
        width: 100%;

    }

    .MuiTabs-root {
        width: 50%;
    }.MuiTabs-flexContainer {
        font-size: 1em;
        width: 50%;
    }.MuiTabs-vertical {
        width: 100%; /* Make tabs full width */
        border-right: none; /* Remove right border */
    }

    .work_tab_panel {
        width: 100%;
        padding: 0; /* Remove left padding */
        min-height: auto; /* Adjust min-height */
        position: static; /* Change position to static */
    }.tab_panel {
        position: static; /* Change position to static */
    }.experience_title {
        font-size: 0.9em; /* Increase title font size */

    } p.experience_description, p.experience_location {
        font-size: 0.7em; /* Adjust description font size */
    }
    
}

/* On screens with width 1921px or more, increase font size */
@media (min-width: 1921px) {
    .home_page_intro {
        margin-top: 300px;
        margin-bottom: 250px;
    } .header-introduction_name {
        font-size: 3.5em;
    } .header-introduction-typer {
        font-size: 1.5em;
    } .about_me_skills > * {
        width: 125px;
    }
}


.scroll-line {
    width: 1px;
    height: 100px;
    background-color: var(--primary-color);
    z-index: 1000;
}
.select-button {
    justify-content: center;
    bottom: 10%;
}


/** Custom Arrow animation */
.the-arrow {
    width: 10px;
    transition: all 0.2s;
} .the-arrow.-left {
    position: absolute;
    top: 60%;
    left: 0;
} .the-arrow.-left > .shaft {
    width: 0;
    background-color: var(--primary-color-light);
} 
.the-arrow.-left > .shaft:before,
.the-arrow.-left > .shaft:after {
    background-color: var(--primary-color-light);
    width: 0;
} .the-arrow.-left > .shaft:before {
    transform: rotate(0);
} .the-arrow.-left > .shaft:after {
    transform: rotate(0);
} .the-arrow.-right {
    top: 3px;
}

.the-arrow.-right > .shaft {
    width: 10px;
    transition-delay: 0.2s;
} 
.the-arrow.-right > .shaft:before,
.the-arrow.-right > .shaft:after {
    width: 8px;
    transition-delay: 0.3s;
    transition: all 0.5s;
}

.the-arrow.-right > .shaft:before {
    transform: rotate(40deg);
} .the-arrow.-right > .shaft:after {
    transform: rotate(-40deg);
} .the-arrow > .shaft {
    background-color: var(--primary-color-light);

    display: block;
    height: 1px;
    position: relative;
    transition: all 0.2s;
    transition-delay: 0;
    will-change: transform;
} 

.the-arrow > .shaft:before,
.the-arrow > .shaft:after {
    background-color: var(--primary-color-light);
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.2s;
    transition-delay: 0;
} .the-arrow > .shaft:before {
    transform-origin: top right;
} .the-arrow > .shaft:after {
    transform-origin: bottom right;
} .animated-arrow {
    display: inline-block;
    color: var(--primary-color-light);
    font-size: 1.25em;
    text-decoration: none;
    position: relative;
    transition: all 0.2s;
} .animated-arrow:hover {
    color: var(--white);
} .animated-arrow:hover > .the-arrow.-left > .shaft {
    width: 64px;
    transition-delay: 0.1s;
    background-color: var(--white);
}

.animated-arrow:hover > .the-arrow.-left > .shaft:before,
.animated-arrow:hover > .the-arrow.-left > .shaft:after {
    width: 8px;
    transition-delay: 0.1s;
    background-color: var(--white);
} .animated-arrow:hover > .the-arrow.-left > .shaft:before {
    transform: rotate(40deg);
} .animated-arrow:hover > .the-arrow.-left > .shaft:after {
    transform: rotate(-40deg);
} .animated-arrow:hover > .main {
    transform: translateX(74px);
} .animated-arrow:hover > .main > .the-arrow.-right > .shaft {
    width: 0;
    transform: translateX(200%);
    transition-delay: 0;
}

.animated-arrow:hover > .main > .the-arrow.-right > .shaft:before,
.animated-arrow:hover > .main > .the-arrow.-right > .shaft:after {
    width: 0;
    transition-delay: 0;
    transition: all 0.1s;
} .animated-arrow:hover > .main > .the-arrow.-right > .shaft:before {
    transform: rotate(0);
} .animated-arrow:hover > .main > .the-arrow.-right > .shaft:after {
    transform: rotate(0);
} .animated-arrow > .main {
    display: flex;
    align-items: center;
    transition: all 0.2s;
}

.animated-arrow > .main > .text {
    margin: 0 10px 0 0;
    line-height: 1;
} .animated-arrow > .main > .the-arrow {
    position: relative;
}

