.line {
	stroke: #fff;
}

.function-based-values-demo {
	display: flex;
	justify-content: center;
	margin-top: 100px;
}

.el {
	width: 50px;
	height: 50px;
	background-color: #fff;
	opacity: 0.5;
	margin: 0 10px;
}

.line-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
}
  