.app_header {
	border-image: linear-gradient(
	  to right,
	  transparent 0%,
	  var(--primary-color) 20%,
	  var(--primary-color) 80%,
	  transparent 100%
	) 1;
	border-bottom: 0.5px solid;
  }

@media (max-width: 500px) {
	.app_header_navigation {
		font-size: 0.5em;
	} .app_header {
		font-size: 0.7em;
	}
}
