.react_link {
	color: var(--white);
	text-decoration: none;		
}

.react_link:visited{
	color: var(--white);
}


@media (max-width: 500px) {
	.t {
		font-size: 0.5em;
	}
}
