
/** Style for the whole page */ 
.phtography-page-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
	background-color: var(--primary-color-dark);
	padding: 2rem;
  }
  
  /** Style for the photography container */
  .masonry-grid-container {
	columns: 5 300px;
	column-gap: 0.6rem;
	row-gap: 0.6rem;
	width: 70%;
	margin: 10% auto;
	padding: 0 2rem;
  }
  
  .photo-item {
	width: 100%;
	margin: 3px;
	break-inside: avoid;
	border-radius: 5px;
	transition: transform 0.3s ease;
  }
  
  .photo-item:hover {
	transform: scale(0.98);
	z-index: 1;
	cursor: pointer;
  }

.photography-loading-icon {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;
	font-family: var(--font-family-ibm-plex-mono);
	color: white;

}
  
  /* Responsive adjustments */
  @media (max-width: 1200px) {
	.photography-masonry-container {
	  columns: 3 250px;
	}
  }
  
  @media (max-width: 768px) {
	.photography-masonry-container {
	  columns: 2 200px;
	}
  }
  
  @media (max-width: 480px) {
	.photography-masonry-container {
	  columns: 1 200px;
	}
  }